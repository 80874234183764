<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-text-field
        v-model="form.description"
        :rules="$rules.required"
        label="Descrição"
        required
        dense
        outlined
      ></v-text-field>

      <v-select
        label="Empresa"
        :items="branches"
        v-model="form.organization_branch_id"
        item-value="id"
        item-text="name"
        required
        dense
        outlined
      ></v-select>

      <v-row>
        <v-col cols="6">
          <v-select
            label="Tarefa"
            :items="taskOptions"
            v-model="form.task"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            label="Configuração de Notificação"
            :items="notificationSettings"
            v-model="form.notification_setting_id"
            item-value="id"
            item-text="description"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            label="A cada"
            :items="byOptions"
            v-model="form.by"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.interval"
            label="Intervalo"
            required
            dense
            outlined
            v-show="showInterval"
          ></v-text-field>
          <v-text-field
            v-model="form.at"
            label="As"
            required
            dense
            outlined
            placeholder="10:00"
            v-show="showAt"
          ></v-text-field>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.v-form {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import TaskSchedulerEnum from '@/enumerations/task_scheduler_enum'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'UsersForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    operations: [],
    errors: [],
    form: {
      description: null,
      organization_branch_id: null,
      task: null,
      notification_setting_id: null,
      by: null,
      interval: null,
      at: null,
    },
    branches: [],
    notificationSettings: [],
    byOptions: TaskSchedulerEnum.by.all,
    taskOptions: TaskSchedulerEnum.task.all,
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  computed: {
    showInterval() {
      return ['day', 'minute', 'hour'].includes(this.form.by)
    },

    showAt() {
      return [
        'day',
        'sunday',
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ].includes(this.form.by)
    },
  },

  methods: {
    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.taskSchedulers
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'TaskSchedulers' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      this.form = { ...this.form, ...data }
    },

    async fetch(id = null) {
      try {
        this.loading = true
        const branchesResult = await Api.branches()
        this.branches = branchesResult.data.branches

        const notficationSettingsResult =
          await Api.notificationSettings.search()
        this.notificationSettings =
          notficationSettingsResult.data.notification_settings

        if (id === null) {
          return
        }

        const taskSchedulersResult = await Api.taskSchedulers.fetch(id)
        this._parseForm(taskSchedulersResult.data.task_scheduler)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar Agendamento de Tarefas')
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true
      Api.taskSchedulers
        .save(this.$route.params.id, { task_scheduler: this.form })
        .then(response => {
          this._parseForm(response.data.task_scheduler)
          this.$root.$children[0].toast(
            'Agendamento de tarefa salvo com sucesso'
          )

          this.$router.push({
            name: 'TaskSchedulerShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast(
              'Erro ao salvar agendamento de tarefa'
            )
            if (error.response.status != 500) {
              this.errors = error.response.data.errors
              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
